import { environment } from '../environments/environment';
export class MenuConfig {
	public defaults: any = {
		aside: {
			self: {},
			items: [
				{
					title: 'Home',
					root: true,
					icon: 'dashboard',
					page: '/',
					permission: [

					],
					bullet: 'dot'
				},
				{
					title: 'Users',
					root: true,
					icon: 'group',
					page: '/users',
					permission: [
						environment.customKeys.roleAdmin
					],
					bullet: 'dot'
				},
				{
					title: 'Podcasts',
					root: true,
					icon: 'podcasts',
					page: '/podcasts',
					permission: [
					],
					bullet: 'dot'
				},
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
